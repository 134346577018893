import * as React from 'react'
import { Link } from 'gatsby'
import useWindowDimensions from '../hooks/useWindowDimensions'
import CookieConsent from 'react-cookie-consent'

const Header = ({ links, texts }) => {
  const { width } = useWindowDimensions()

  const isWidescreen = width >= 1500
  let breadCrumbs = []
  for (let i = 0; links && i < links.length; i++) {
    if (i === 0) {
      breadCrumbs.push(
        <Link
          key={i}
          style={{
            whiteSpace: 'pre-wrap',
            textDecoration: 'none',
            color: 'blue',
          }}
          to={links[i]}
        >
          {texts[i]}
        </Link>
      )
    } else {
      breadCrumbs.push(' ')
      breadCrumbs.push(
        <p style={{ whiteSpace: 'pre-wrap' }} key={i}>
          <span> {'>'}</span>
          <Link style={{ textDecoration: 'none', color: 'blue' }} to={links[i]}>
            {' '}
            {texts[i]}
          </Link>
        </p>
      )
    }
  }
  return (
    <div>
      {/* inject cookie consent on every page */}
      <CookieConsent
        enableDeclineButton
        declineButtonText={'No thanks'}
        declineButtonStyle={{ color: 'white' }}
        buttonText={'Accept cookies'}
        buttonStyle={{ background: '#537A60', color: 'white' }}
        style={{ justifyContent: 'center' }}
        contentStyle={{ flex: 'none' }}
        onDecline={() => {
          window[`ga-disable-UA-187798284-1`] = true
        }}
      >
        This website needs cookies to function optimally
      </CookieConsent>
      <div style={{ height: '120px' }}></div>
      <div
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '100',
          left: 0,
          top: 0,
          marginBottom: '100px',
        }}
      >
        <div
          style={{
            textAlign: 'left',
            boxShadow: '0 2px 3px rgba(0,0,0,.2)',
            background: 'white',
          }}
        >
          <div
            style={{
              height: '4px',
              width: '100%',
              maxWidth: '100%',
              background: '#537A60',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'flex-row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h2
              style={{
                marginLeft: '1.8rem',
                marginTop: '12px',
                left: 0,
              }}
            >
              <Link style={{ textDecoration: 'none' }} to="/">
                money optic
              </Link>
            </h2>
            <p>
              <Link
                style={{ textDecoration: 'none', marginRight: '1.8rem' }}
                to="/menu"
              >
                Menu
              </Link>
            </p>
            {/* <div
              style={{
                justifySelf: 'center',
                marginRight: 'calc(50%)',
                display: 'flex',
                alignItems: 'center',
              }}
            ></div> */}
          </div>
        </div>
        {isWidescreen && !!breadCrumbs.length && (
          <div
            style={{
              position: 'relative',
              marginLeft: '1.8rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {breadCrumbs}
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
